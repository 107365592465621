<template>
  <div class="w-100 position-relative" style="overflow-y: auto">
    <CCard class="pdf-wrapper">
      <div class="modal-body modal-container">
        <div class="pdf-wrapper">
          <div class="pdf-page fs-16">
            <div id="pdf1" class="pdf-content ml-3 mr-3">
              <div class="text-center fs-16">
                <strong class="bold">Mẫu C.III.3</strong><br>
                <strong>Văn bản thông báo kế hoạch tổ chức hoạt động</strong><br>
                <strong>xúc tiến không sử dụng ngân sách nhà nước</strong><br>
                <p class="font-italic">(Khoản 3, Điều 96 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021)</p>
                <hr>
              </div>
              <div class="row fs-16">
                <div class="col-md-6">
                  <p>{{item.tenDonViToChuc}}</p>
                  <p>{{item.soVanBan}}</p>
<!--                  <CInput class="custom-input" v-model="item.tenDonViToChuc" placeholder="TÊN ĐƠN VỊ TỔ CHỨC/CÁ NHÂN"-->
<!--                          horizontal/>-->
<!--                  <CInput class="custom-input" placeholder="Số:" v-model="item.soVanBan" horizontal/>-->
                </div>
<!--                <div class="col-md-4"/>-->
                <div class="col-md-6">
                  <p class="text-center text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt Nam</p>
                  <p class="text-center font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>
                  <p class="font-weight font-italic" style="text-align: center">
                    {{item.diaDiem}}, ngày {{ getDay(item.ngayVanBan)}} tháng {{ getMonth(item.ngayVanBan)}} năm {{ getYear(item.ngayVanBan) }}
<!--                    <CInput placeholder="Địa điểm:" horizontal :value.sync="item.diaDiem"/>-->
<!--                    <CInput type="date" placeholder="Ngày văn bản:" horizontal :value.sync="item.ngayVanBan"/>-->
<!--                    {{item.diaDiem}}, ngày ... tháng ... năm ...-->
<!--                    <span class="mt-2">, ngày {{getDay(item.ngayVanBan) }} tháng {{ getMonth(item.ngayVanBan) }} năm {{ getYear(item.ngayVanBan) }}</span>-->
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4" style="float: left">
                  <p class="font-weight">V/v thông báo kế hoạch tổ chức hoạt động xúc tiến hỗn hợp đầu tư, thương mại, du lịch, ngoại giao kinh tế</p>
                </div>
              </div>
              <div class="text-center">
                <p>Kính gửi: Bộ Kế hoạch và Đầu tư</p>
              </div>
              <div class="ml-3">
                <p><span class="pl-3">Căn cứ quy</span> định tại Khoản 3 Điều 96 Nghị định số 31/2021/NĐ-CP ngày
                  26/3/2021 quy định chi tiết và hướng dẫn thi hành một số điều của Luật Đầu tư, <span
                    class="font-italic">(Tên đơn vị tổ chức/Cá nhân)</span>
                  xin thông báo kế hoạch tổ chức các hoạt động xúc tiến đầu tư như sau
                </p>
              </div>
              <div class="row">
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>1. Tên hoạt động xúc tiến đầu tư:</label>
                  </div>
                  <div class="col-8">
                    {{item.tenHoatDong}}
<!--                    <CInput placeholder="Nhập tên hoạt động đầu tư" horizontal v-model="item.tenHoatDong"/>-->
                  </div>
                </div>
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>2. Mục tiêu:</label>
                  </div>
                  <div class="col-8">
                    {{item.mucTieu}}
<!--                    <CInput placeholder="Nhập mục tiêu" horizontal v-model="item.mucTieu"/>-->
                  </div>
                </div>
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>3. Thời gian thực hiện:</label>
                  </div>
                  <div class="col-4">
                    {{getDate(item.thoiGianThucHien)}}
<!--                    <v-date-picker class="" v-model="item.thoiGianThucHien" locale="vi" type="datetime">-->
<!--                      <template v-slot="{ inputValue, inputEvents }">-->
<!--                        <CInput-->
<!--                          :value="inputValue"-->
<!--                          v-on="inputEvents"-->
<!--                          placeholder="Chọn ngày"-->
<!--                        />-->
<!--                      </template>-->
<!--                    </v-date-picker>-->
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">4. Địa điểm (trong nước/nước ngoài):</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Trong nước:</li>
                        <li class="mt-4">Nước ngoài:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <p>{{item.diaDiem_TrongNuoc}}</p>
                      <p>{{item.diaDiem_NuocNgoai}}</p>
<!--                      <CSelect class="form-group" placeholder="Chọn tỉnh thành" :value.sync="item.diaDiem_TrongNuoc"-->
<!--                               :options="optionsTinhThanh" horizontal/>-->
<!--                      <CSelect class="form-group" placeholder="Chọn quốc gia" :value.sync="item.diaDiem_NuocNgoai"-->
<!--                               :options="optionsQuocGia" horizontal/>-->
                    </div>
                  </div>
                </div>
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>5. Thành phần dự kiến tham gia:</label>
                  </div>
                  <div class="col-8">
                    {{item.thanhPhanDuKienThamGia}}
<!--                    <CInput class="form-group" v-model="item.thanhPhanDuKienThamGia"-->
<!--                            placeholder="Nhập các thành phần dự kiến tham gia" horizontal/>-->
                  </div>
                </div>
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>6. Quy mô hoạt động:</label>
                  </div>
                  <div class="col-8">
                    {{item.quyMoHoatDong}}
<!--                    <CInput class="form-group" v-model="item.quyMoHoatDong" placeholder="Nhập quy mô hoạt động"-->
<!--                            horizontal/>-->
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">7. Lĩnh vực/Địa bàn kêu gọi đầu tư:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Ngành/Lĩnh vực kêu gọi đầu tư:</li>
                        <li class="mt-4">Địa bàn kêu gọi đầu tư:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <p>{{item.nganhLinhVucKeuGoiDauTuId}}</p>
                      <p>{{item.diaBanKeuGoiDauTu}}</p>
<!--                      <CSelect class="form-group" placeholder="Chọn ngành/lĩnh vực"-->
<!--                               :value.sync="item.nganhLinhVucKeuGoiDauTuId" horizontal :options="optionsNganhLinhVuc"/>-->
<!--                      <CSelect class="form-group" placeholder="Chọn địa bàn" :value.sync="item.diaBanKeuGoiDauTu" horizontal-->
<!--                               :options="optionsTinhThanh"/>-->
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 ml-3">
                  <label>8. Các hoạt động chính:
                    <span class="font-italic">(bao gồm danh mục giấy chứng nhận đăng ký đầu tư/chấp thuận chủ trương đầu
                      tư/thỏa thuận dự kiến trao/ký kết)</span>
                  </label>
                  <div class="col-12">
                    {{item.cacHoatDongChinh}}
<!--                    <CInput class="form-group" placeholder="Nhập các hoạt động chính" horizontal-->
<!--                            v-model="item.cacHoatDongChinh"/>-->
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">9. Cơ quan, tổ chức, doanh nghiệp, cá nhân phối hợp thực hiện:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Tên tổ chức/cơ quan, doanh nghiệp, cá nhân trong nước:</li>
                        <li class="mt-4">Tên tổ chức/cơ quan, doanh nghiệp, cá nhân nước ngoài:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <p>{{item.toChucCoQuan_TrongNuoc}}</p>
                      <br>
                      <br>
                      <p>{{item.toChucCoQuan_NuocNgoai}}</p>
<!--                      <CInput class="form-group" v-model="item.toChucCoQuan_TrongNuoc"-->
<!--                              placeholder="Chọn tên tổ chức/cơ quan trong nước" horizontal-->
<!--                      />-->
<!--                      <CInput class="form-group" v-model="item.toChucCoQuan_NuocNgoai"-->
<!--                              placeholder="Chọn tên tổ chức/cơ quan nước ngoài" horizontal-->
<!--                      />-->
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 ml-3">
                  <label>10. Chương trình dự kiến:</label>
                  <div class="col-12">
                    {{item.chuongTrinhDuKien}}
<!--                    <CInput class="form-group" v-model="item.chuongTrinhDuKien" placeholder="Nhập chương trình dự kiến"-->
<!--                            horizontal-->
<!--                    />-->
                  </div>
                </div>
                <div class="col-xl-12 ml-3">
                  <label>11. Phương án tổ chức thực hiện:</label>
                  <div class="col-12">
                    {{item.phuongAnToChucThucHien}}
<!--                    <CInput class="form-group" v-model="item.phuongAnToChucThucHien"-->
<!--                            placeholder="Nhập phương án tổ chức thực hiện" horizontal-->
<!--                    />-->
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">11. Kinh phí:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Ngân sách:</li>
                        <li class="mt-4">Nguồn xã hội hóa:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <p>{{item.kinhPhi_NganSach}}</p>
                      <p>{{item.kinhPhi_XaHoiHoa}}</p>
<!--                      <CInput class="form-group" :value.sync="item.kinhPhi_NganSach"-->
<!--                              placeholder="Nhập kinh phí nguồn ngân sách" horizontal-->
<!--                      />-->
<!--                      <CInput class="form-group" :value.sync="item.kinhPhi_XaHoiHoa"-->
<!--                              placeholder="Nhập kinh phí nguồn xã hội hóa" horizontal/>-->
                    </div>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">12. Thông tin liên hệ:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-2">
                      <ul>
                        <li>Họ tên:</li>
                        <li class="mt-4">Email:</li>
                      </ul>
                    </div>
                    <div class="col-4">
                      <p>{{item.lienHe_HoTen}}</p>
                      <p>{{item.lienHe_Email}}</p>
<!--                      <CInput class="form-group" v-model="item.lienHe_HoTen" placeholder="Nhập họ tên" horizontal/>-->
<!--                      <CInput class="form-group" v-model="item.lienHe_Email" placeholder="Nhập email" horizontal/>-->
                    </div>
                    <div class="col-2">
                      <ul>
                        <li class="">Số điện thoại:</li>
                      </ul>
                    </div>
                    <div class="col-4">
                      {{item.lienHe_SoDienThoai}}
<!--                      <CInput class="form-group" v-model="item.lienHe_SoDienThoai" placeholder="Nhập số điện thoại"-->
<!--                              horizontal-->
<!--                      />-->
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 row">
                  <div class="col">
                    <div class="float-left">
                      <span class="font-italic font-weight-bold">Nơi nhận:</span> <br>
                      <span class="font-weight">- Như trên;</span> <br>
                      <span class="font-weight">- Bộ Kế Hoạch và Đầu tư (để t/b);</span> <br>
                      <span class="font-weight">- Lưu: VT,.......</span> <br>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12">
                  <div class="float-right mr-5" style="text-align: center">
                    <span class="font-weight-bold text-uppercase">ĐẠI DIỆN CÓ THẨM QUYỀN CỦA ĐƠN VỊ TỔ CHỨC</span> <br>
                    <span class="font-italic text-align">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span><br>
                    <span class="font-weight-bold text-uppercase">CÁ NHÂN</span><br>
                    <span class="font-italic text-align">(Ký, ghi rõ họ tên)</span><br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <span class="font-italic text-align">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span><br>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label>File đính kèm:</label>
                  <div class="import-files mb-2" v-if="Attachments.length">
                    <div class="files mb-1" v-for="file in Attachments" v-bind:key="file.id">
                      <a :href="getDownloadURL(file.url)" target="_blank">{{ file.fileName }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CCardFooter tag="div" class="text-center">
          <CButton color="secondary" class="mr-2" @click="back">Quay lại</CButton>
          <CButton color="ghost" class="mr-2" @click="print">Xuất văn bản</CButton>
          <CButton color="danger" @click="isDeleteConfirmationModalOpen = true">Xóa</CButton>
        </CCardFooter>
      </div>
    </CCard>
    <DeleteConfirmationModal
      title="Xóa thông báo kế hoạch tổ chức hoạt động xúc tiến hỗn hợp đầu tư, thương mại, du lịch, ngoại giao kinh tế"
      :show.sync="isDeleteConfirmationModalOpen"
      :is-deleting="isDeleting"
      :item="item" @confirm-destroy="confirmDestroy"/>
  </div>
</template>

<script>
import { statuses, loaiDonVis } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import {
  DELETE_THONGBAOTOCHUC,
  GET_THONGBAOTOCHUC
} from '@/store/modules/VanBanTBTCHDXTDTKSDNSNN/actionTypes'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import moment from 'moment'
import appConfig from '@/shared/appConfig'

export default {
  name: 'ThongBaoToChucDetail',
  data () {
    return {
      Attachments: [],
      type: 'ThongBaoToChuc',
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      isDeleteConfirmationModalOpen: false,
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsNganhLinhVuc: []
    }
  },
  computed: {
    ...mapGetters('thongBaoToChuc', {
      item: 'detailItem',
      // isFetchingSingle: 'isFetchingSingle',
      isDeleting: 'isDeleting',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('thongBaoToChuc', {
      getItem: GET_THONGBAOTOCHUC,
      deleteItem: DELETE_THONGBAOTOCHUC
    }),
    getDownloadURL (url) {
      return url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ? url : appConfig.BASE_HOST + url
    },
    getDate: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD/MM/YYYY') : '',
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : '',
    back () {
      this.$router.push({ path: '/thong-bao-to-chuc-hd-xtdt' })
    },
    editItem () {
      this.$router.push({ path: `/thong-bao-to-chuc-hd-xtdt/${this.item.id}/cap-nhat` })
    },
    print () {
      localStorage.setItem('thong_bao_to_chuc', JSON.stringify(this.item))
      // this.$router.push({ path: `/thong-bao-ke-hoach-xt-hon-hop/${this.item.id}/bieu-in` })
      const routeData = this.$router.resolve({ path: '/thong-bao-to-chuc-hd-xtdt/bieu-in' })
      window.open(routeData.href, '_blank')
      // this.$store.state.documentEdit.show = false
      // this.$store.state.printThongBaoToChuc = {
      //   ...this.$store.state.printThongBaoToChuc,
      //   show: true,
      //   style: { display: 'flex' },
      //   class: 'ms-motion-scaleDownIn',
      //   data: this.item
      // }
    },
    async confirmDestroy () {
      await this.deleteItem(this.item)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.$router.push({ path: '/thong-bao-to-chuc-hd-xtdt' })
      }
    }
  },
  async created () {
    await this.getItem(this.$route.params.id)
    if (this.item.dinhKem) this.Attachments = JSON.parse(this.item.dinhKem)
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
  }
}
</script>

<style scoped>

</style>
